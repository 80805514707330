<template>
  <div>
    <Hero />
    <HowItWorks />
    <MoreFeatures />
    <BnCTA />
    <OahOverview class="space-top-2" />
  </div>
</template>

<script>
import Hero from './components/hero'
import HowItWorks from './components/how-it-works'
import MoreFeatures from './components/more-features'
import BnCTA from './components/bn-cta'
import OahOverview from '@/components/oah-overview'

export default {
  name: 'AiGateway',
  components: {
    Hero,
    HowItWorks,
    MoreFeatures,
    BnCTA,
    OahOverview
  },
  metaInfo() {
    return {
      title: 'FabriXAPI',
      titleTemplate: 'AI Gateway | %s',
      link: [
        { rel: 'canonical', href: 'https://www.fabrixapi.com/ai-gateway' }
      ],
      meta: [
        { name: 'description', content: 'Unleash AI\'s Potential with FabriXAPI\'s Gateway. Seamlessly integrate and optimize AI models for security and performance.' },
        { property: 'og:site_name', content: 'FabriXAPI' },
        { property: 'og:title', content: 'AI Gateway | FabriXAPI' },
        { property: 'og:description', content: 'Unleash AI\'s Potential with FabriXAPI\'s Gateway. Seamlessly integrate and optimize AI models for security and performance.' },
        { property: 'og:image', content: 'https://www.fabrixapi.com/assets/img/thumbnails/fabrixapi-thumbnail.jpg' },
        { property: 'og:url', content: 'https://www.fabrixapi.com/ai-gateway' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
