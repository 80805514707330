<template>
  <div>
    <div class="bg-light">
      <div id="ctaSection" class="container space-2 cta-bg">
        <div class="text-center w-md-60 mx-auto">
          <h2 class="mb-3">Ready to Embark on the APIs Meet AI Journey with FabriXAPI?</h2>
          <p class="mb-4">Be the first to receive updates by joining our AI Gateway Waitlist!</p>
          <div class="form-subscribe ml-4">
            <!-- Begin Mailchimp Signup Form -->
            <div id="mc_embed_signup">
              <form id="mc-embedded-subscribe-form" ref="mcEmbeddedSubscribeForm" action="https://fabrixapi.us18.list-manage.com/subscribe/post?u=b928e5178a4f8a241b088732f&amp;id=221f0de8c7&amp;f_id=003396e6f0" method="post" name="mc-embedded-subscribe-form" class="validate" target="blank">
                <div class="mc-field-group">
                  <label for="mce-EMAIL">Email Address <span class="asterisk">*</span>
                  </label>
                  <input id="mce-EMAIL" type="email" value="" name="EMAIL" class="required email" required>
                  <span id="mce-EMAIL-HELPERTEXT" class="helper_text" />
                </div>
                <div class="mb-4">
                  <p class="small">By submitting your information, you agree to receive future communications from FabriXAPI by beNovelty.</p>
                </div>
                <div hidden="true"><input type="hidden" name="tags" value="2979393"></div>
                <div id="mce-responses" class="clear">
                  <div id="mce-error-response" class="response" style="display:none" />
                  <div id="mce-success-response" class="response" style="display:none" />
                </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_b928e5178a4f8a241b088732f_221f0de8c7" tabindex="-1" value=""></div>
                <div class="clear"><input id="mc-embedded-subscribe" type="submit" value="Join Waitlist" name="subscribe" class="btn btn-wide btn-primary border-0 mx-auto mb-0"></div>
              </form>
            </div>
          <!--End mc_embed_signup-->
          <!-- <form ref="subscriptionForm" /> -->
          </div>
        </div>
      </div>
      <!-- SVG Background Shape -->
      <!-- <figure class="w-100 position-absolute bottom-0 left-0">
        <img class="img-fluid" src="@/assets/svg/components/isometric-lego.svg">
      </figure> -->
    <!-- End SVG Background Shape -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'BnCTA'
}
</script>

<style lang="scss" scoped>
// :style="{ background: `url(${require(`@/assets/svg/components/isometric-lego.svg`)}) center no-repeat`}"
</style>
