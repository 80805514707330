<template>
  <div id="how-it-works" class="position-relative overflow-hidden">
    <div class="container space-2">
      <div class="w-md-80 text-center mx-md-auto mb-5 mb-md-9">
        <span class="d-block small font-weight-bold text-cap mb-2">Why AI Gateway?</span>
        <h2>Supercharge AI integration in your applications</h2>
      </div>
      <div class="row">
        <div class="col-md-4 mb-5 mb-md-0">
          <div class="text-center">
            <figure class="max-w-10rem mx-auto mb-4">
              <img class="img-fluid" src="@/assets/svg/icons/icon-47.svg">
            </figure>
            <h3>Streamline AI traffic usage, security, and monitoring</h3>
            <p>Effortlessly utilize, safeguard, and monitor the leading Large Language Models (LLMs) such as OpenAI, Azure AI, Cohere, Anthrophic, LLamMA, and Mistral.</p>
          </div>
        </div>

        <div class="col-md-4 mb-5 mb-md-0">
          <div class="text-center">
            <figure class="max-w-10rem mx-auto mb-4">
              <img class="img-fluid" src="@/assets/svg/icons/icon-44.svg">
            </figure>
            <h3>Harness advanced AI contexts and prompts</h3>
            <p>Integrate sophisticated prompt engineering techniques into your AI requests to ensure compliance and governance in AI implementation.</p>
          </div>
        </div>

        <div class="col-md-4">
          <div class="text-center">
            <figure class="max-w-10rem mx-auto mb-4">
              <img class="img-fluid" src="@/assets/svg/icons/icon-3.svg">
            </figure>
            <h3>Seamlessly integrate AI with no-code solutions</h3>
            <p>Enhance your existing API traffic with AI capabilities using our user-friendly, code-free AI plugins, eliminating the need for manual coding in your applications.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'bootstrap'
const $ = require('jquery')

export default {
  name: 'HowItWorks',
  mounted() {
    $('#videoModal').on('hidden.bs.modal', function(e) {
      $('#videoModal iframe').attr('src', $('#videoModal iframe').attr('src'))
    })
  }
}
</script>

<style lang="scss" scoped>
.modal-header {
  border-bottom: 0;
}
</style>
