<template>
  <div class="position-relative bg-img-hero" :style="{ backgroundImage: `url(${require(`@/assets/svg/components/ben-industry-hero.svg`)})`}">
    <div class="container space-top-3 space-bottom-2">
      <div class="w-md-85 w-lg-75 mx-auto text-center">
        <h1>Building and Adopting AI Faster with FabriXAPI AI Gateway</h1>
        <p class="mb-4">We have recently expanded our feature set to include an AI and API Gateway, embracing the growing trend of AI. As this feature is currently in the Beta phase, we encourage you to join our AI Gateway waitlist to be among the first to experience this exciting new capability!</p>
        <div class="form-subscribe mb-7 ml-4">
          <!-- Begin Mailchimp Signup Form -->
          <div id="mc_embed_signup">
            <form id="mc-embedded-subscribe-form" ref="mcEmbeddedSubscribeForm" action="https://fabrixapi.us18.list-manage.com/subscribe/post?u=b928e5178a4f8a241b088732f&amp;id=221f0de8c7&amp;f_id=003396e6f0" method="post" name="mc-embedded-subscribe-form" class="validate" target="blank">
              <div class="mc-field-group">
                <label for="mce-EMAIL">Email Address <span class="asterisk">*</span>
                </label>
                <input id="mce-EMAIL" type="email" value="" name="EMAIL" class="required email" required>
                <span id="mce-EMAIL-HELPERTEXT" class="helper_text" />
              </div>
              <div class="mb-4">
                <p class="small">By submitting your information, you agree to receive future communications from FabriXAPI by beNovelty.</p>
              </div>
              <div hidden="true"><input type="hidden" name="tags" value="2979393"></div>
              <div id="mce-responses" class="clear">
                <div id="mce-error-response" class="response" style="display:none" />
                <div id="mce-success-response" class="response" style="display:none" />
              </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
              <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_b928e5178a4f8a241b088732f_221f0de8c7" tabindex="-1" value=""></div>
              <div class="clear"><input id="mc-embedded-subscribe" type="submit" value="Join Waitlist" name="subscribe" class="btn btn-wide btn-primary border-0 mx-auto mb-0"></div>
            </form>
          </div>
          <!--End mc_embed_signup-->
          <!-- <form ref="subscriptionForm" /> -->
        </div>
        <img class="img-fluid" src="@/assets/svg/illustrations/ai-gateway.svg" alt="OpenAPIHub Developer Portal">
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Hero'
}
</script>

<style lang="scss" scoped>
.modal-header {
  border-bottom: 0;
}
</style>
