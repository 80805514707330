<template>
  <div id="more-features">
    <div class="container space-2 space-bottom-3">
      <div class="w-md-80 w-lg-65 text-center mx-md-auto mb-5">
        <h2 class="mb-3">Want to Know More About AI / API Gateway?</h2>
        <p>Let's dive into AI / API Gateway with ur curated articles</p>
      </div>

      <div class="row w-lg-80 mx-auto">
        <div class="col-md-6 mb-4 mb-md-0">
          <a class="card h-100" href="https://blog.openapihub.com/en-us/role-of-ai-gateways-in-building-ai-apps-at-scale/" target="_blank">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/thumbnails/ai-gateways.jpg">
              <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                  <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                </svg>
              </figure>
            </div>
            <div class="card-body">
              <div class="mb-5">
                <h4>From APIs to AI: The Role of AI Gateways in Building AI Apps at Scale</h4>
                <p class="font-size-1">In the world of software development, API Gateways serve as crucial connectors between clients and…</p>
              </div>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </a>
        </div>
        <div class="col-md-6 mb-0">
          <a class="card h-100" href="https://blog.openapihub.com/en-us/navigating-api-gateway-a-guide-for-app-developers/" target="_blank">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/thumbnails/navigating-api-gateway.jpg">
              <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                  <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                </svg>
              </figure>
            </div>
            <div class="card-body">
              <div class="mb-5">
                <h4>Navigating API Gateway: A Guide for App Developers</h4>
                <p class="font-size-1">As emerging developers in the tech industry, understanding the infrastructure that powers modern applications is crucial...</p>
              </div>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MoreFeatures',
  data() {
    return {
      Features: [
        {
          title: 'OpenAPI Spec (OAS) Support',
          description: 'Support OpenAPI Specification / Swagger files to build API Collections with industry standards',
          icon: 'icon-12.svg'
        },
        {
          title: 'API Document & Markdown',
          description: 'Enable interactive API Document with try it out features for a better developer experience (DX)',
          icon: 'icon-44.svg'
        },
        {
          title: 'Theme & Content Customization',
          description: 'Update customized contents and styling with online portal editor',
          icon: 'icon-36.svg'
        },
        {
          title: 'Portal Access Control',
          description: 'Built-in API Portal Access Control for Private, Partner or Public APIs',
          icon: 'icon-20.svg'
        },
        {
          title: 'SEO & i18n Support',
          description: 'Advanced SEO and i18n Support for API Discovery',
          icon: 'icon-25.svg'
        },
        {
          title: 'Administrative CI/CD Pipeline',
          description: 'APIOps integration GitHub/Gitlab via CI/CD Pipeline',
          icon: 'icon-3.svg'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
